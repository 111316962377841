
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import City from "@/models/City";
import { FieldEmail, FieldPhone, FieldInput } from "@/components/account";
import rules from "@/components/account/rules";
import { mapActions, mapState } from "vuex";
import { notifyInfo, notifySuccess } from "@/components/Notification";

export default Vue.extend({
  name: "btn-create-company",
  components: {
    FieldEmail,
    FieldPhone,
    FieldInput,
  },
  data() {
    return {
      dialog: false,
      rules: rules,
      formValid: false,
      editMode: false,
      toEdit: "",
      stateCities: [],
      loadingCities: false,
      state: 0,
      newPhone: "",
      form: {
        name: "",
        email: "",
        phone: "",
        description: "",
        address: "",
        facebookUrl: "",
        instagramUrl: "",
        mailingZip: "",
        cityId: 0,
        phones: [],
      },
    };
  },
  watch: {
    state: function (stateId: number): void {
      if (stateId == undefined || stateId == null) {
        this.stateCities = [];
        this.loadingCities = false;

        return;
      }

      this.loadingCities = true;
      this.actGetCityByState(stateId).then((cities: City[]) => {
        this.loadingCities = false;
        (this.stateCities as any) = cities;
      });
    },
  },
  computed: {
    ...mapState("crmCompaniesModule", ["loading"]),
    ...mapState("crmMedicFormModule", ["states", "loadingStates"]),
    validaADDPhone() {
      const path = /^[1-9][0-9]{9}$/;
      if (
        this.newPhone != null &&
        this.newPhone != undefined &&
        this.newPhone != ""
      ) {
        if (path.test(this.newPhone)) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actListStates", "actGetCityByState"]),
    ...mapActions("crmCompaniesModule", [
      "actCreateCompany",
      "actUpdateCompany",
    ]),
    create() {
      const { form } = this;
      const body = {
        ...form,
      };

      if (this.editMode) {
        this.actUpdateCompany(this.toEdit, this.form).then(() => {
          this.cancelAll();
          notifyInfo("Company update successfully");
        });

        return;
      }

      this.actCreateCompany(body).then(() => {
        this.cancelAll();
        notifySuccess("Company created successfully");
      });
    },
    setToEdit(company: any) {
      this.form.name = company.name;
      this.form.email = company.email;
      this.form.phone = company.phone;
      this.form.description = company.description;

      this.dialog = true;
    },

    addNewPhone() {
      (this as any).form.phones.push("+1" + (this as any).newPhone);
      (this as any).newPhone = "";
    },
    deleteNumber(event: any) {
      this.form.phones = this.form.phones.filter((p) => p != event);
    },

    cancelAll() {
      this.dialog = false;
      this.form = {
        name: "",
        email: "",
        phone: "",
        description: "",
        address: "",
        facebookUrl: "",
        instagramUrl: "",
        mailingZip: "",
        cityId: 0,
        phones: [],
      };
    },
  },
  async mounted() {
    await this.actListStates();
  },
});
