
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import BtnCreateCompany from "../buttons/BtnCreateCompany.vue";
import BtnRequestCompanies from "../buttons/BtnRequestCompanies.vue";

export default Vue.extend({
  name: "crm-secondary-toolbar",
  components: { BtnCreateCompany, BtnRequestCompanies },
});
