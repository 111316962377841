
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "btn-request-companies",
  computed: {
    ...mapState("crmCompaniesModule", ["loading"]),
  },
  methods: {
    ...mapActions("crmCompaniesModule", ["actGetCompanies"]),
    update(): void {
      this.actGetCompanies();
    },
  },
});
